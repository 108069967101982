
.event-2-imgs{
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-content: center;
}
.event-2-imgs img {
  justify-self: center;
}
@media only screen and (max-width: 600px) {
  .event-2-imgs {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 600px) {
  .event-2-imgs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
 
  .event-2-imgs {
    grid-template-columns: repeat(3, 1fr);
  }
}


