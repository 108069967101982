.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0);
  opacity: 0.9;
  top: 0;
  left: 0;
}

.modal-wrapper {
  padding: 10px;
  margin: 10px auto;
  box-shadow: 0px 2px 6px #000;
  position: fixed;
  width: 100%;
  max-width: 700px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111;
  background-color: #fff;
}

.slider {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
}

.slide {
  position: relative;
  display: none;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.slide.active {
  display: block;
  animation-name: fade;
  animation-duration: 1.5s;
}

.slide span {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.slide img {
  width: 100%;
}

.navigation-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  margin: 0 2px;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 3px solid gray;
}

.preview.active {
  border-color: skyblue;
}

.navigation-next-prev .next-prev {
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  cursor: pointer;
  transform: translateY(-100%);
  z-index: 10000;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.image-list {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.image-list > div {
  flex: 1 33%;
  padding: 5px;
  cursor: pointer;
}

.image-list > div img {
  width: 100%;
  border: 3px solid gray;
}

.image-list > div.active img {
  border-color: skyblue;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-close {
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .image-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 600px) {
  .image-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .image-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
